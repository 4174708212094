<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="flex px-4 add_post text-light bg-primary">
      <feather-icon
        icon="ChevronLeftIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer h2-class"
      ></feather-icon>
      <vs-button class="h2-class h3-upp-class">My Group</vs-button>
    </div>
    <div title class="profile_hd_pd web-scrlrt mt-4">
      <ul class="page-suggestions-list">
        <li
          class="page-suggestion flex items-center mb-4 pr-5 pl-5"
          v-for="group of userGroups"
          :key="group.groupId"
        >
          <div class="mr-3">
            <vs-avatar
              class="m-0"
              v-if="group.group_icon != null"
              :src="imageBaseUrl + group.group_icon"
              size="35px"
            />
            <vs-avatar
              class="m-0"
              v-if="group.group_icon == null"
              :src="imageBaseUrl + 'uploads/defaultImages/default_group.png'"
              size="35px"
            />
          </div>
          <div class="leading-tight">
            <p class="font-medium">{{ group.group_name }}</p>
            <span class="text-xs">{{ group.group_description }}</span>
          </div>
        </li>
      </ul>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
const dashboardRepository = RepositoryFactory.get("dashboard");
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import jwt from "jsonwebtoken";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { category, id, img, name, order_status, price } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.dataId = id;
        this.dataCategory = category;
        this.dataImg = img;
        this.dataName = name;
        this.dataOrder_status = order_status;
        this.dataPrice = price;
        this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      userGroups: [],
      dataId: null,
      dataName: "",
      dataCategory: null,
      dataImg: null,
      dataOrder_status: "pending",
      dataPrice: 0,

      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" }
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    },
    imageBaseUrl() {
      return this.baseUrlMagitt;
    }
  },
  methods: {
    async getGroupsForDashboard() {
      let obj = {};
      if (
        this.$store.state.memberId != this.token.userID &&
        this.$store.state.memberId != undefined &&
        this.$store.state.memberId != null
      ) {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName
          },
          userData: {
            userId: this.$store.state.memberId
          }
        };
      } else {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName
          },
          userData: {
            userId: this.token.userID
          }
        };
      }

      const { data } = await dashboardRepository.getGroupsForDashboard(obj);

      this.userGroups = data;
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = "";
      this.dataCategory = null;
      this.dataOrder_status = "pending";
      this.dataPrice = 0;
      this.dataImg = null;
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mounted() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
    this.getGroupsForDashboard();
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
