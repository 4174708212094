<template>
  <div class="lg:text-left md:text-left text-center">
    <p v-if="item.isOwner" class="mt-4 mb-1 font-black">COMUNITY OWNER</p>
    <p v-else-if="item.isManager" class="mt-4 mb-1 font-black">
      COMMUNITY MANAGER
    </p>
    <p v-else-if="item.isNormalUser" class="mt-4 mb-1 font-black">READER</p>
    <p v-else-if="item.status != 0" class="mt-4 mb-1 font-black">
      CHANNEL ADMIN
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
