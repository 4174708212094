<template>
  <div class="flex flex-wrap lg:justify-start md:justify-start justify-center">
    <p v-if="item.mobile" class="lg:mr-4">
      <span class="font-semibold">Mobile: </span> {{ item.mobile }}
    </p>
    <p v-if="item.email" class="lg:mr-4">
      <span class="font-semibold">Email </span> {{ item.email }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
