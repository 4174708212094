<!-- =========================================================================================
  File Name: Profile.vue
  Description: Profile Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="profile-page" class>
    <!-- PROFILE HEADER -->
    <div class="profile-header">
      <data-view-sidebar
        :isSidebarActive="addNewDataSidebar"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
      />
      <div class="relative">
        <!-- <div class="cover-container">
          <img
            v-if="userDetails.usersDetails_UserCoverImage != null"
            :src="imageBaseUrl + userDetails.usersDetails_UserCoverImage"
            alt="user-profile-cover"
            class="profCover-bg"
          />

          <img
            v-if="userDetails.usersDetails_UserCoverImage == null"
            :src="imageBaseUrl + 'uploads/defaultImages/default_cover.jpg'"
            alt="user-profile-cover"
            class="profCover-bg"
          />
          <input
            id="prof-user-cover-Upload"
            @change="uploadUserCoverImage"
            type="file"
            accept="image/*"
            class="hidden"
          />
        </div> -->
        <!-- <div class="Profile_page_cover_editpart">
          <vs-button
            v-if="
              profileUserId == token.userID ||
                profileUserId == undefined ||
                profileUserId == null
            "
            icon-pack="feather"
            onclick="document.getElementById('prof-user-cover-Upload').click();"
            class="profile_rd_sml Profile_page_cover_colr_back cover_edit"
            type="transparent"
            radius
            icon="icon-edit-2"
          ></vs-button>
          <vs-popup
            classContent="popup-example"
            title="Change your cover picture"
            :active.sync="popupActive3"
          >
            <div class="vx-row">
              <div class="vx-col w-full datasidebarprofile-padding_cropper">
                <div class="flex items-start flex-col sm:flex-row w-full">
                  <div class="w-full datasidebarprofile-cropper-grp">
                    <Cropper
                      class="mr-8 rounded w-full"
                      :src="newUserCoverImage"
                      ref="cropperCover"
                      :stencilProps="stencilPropsCoverImage"
                    />
                  </div>
                  <input
                    id="user-cover-Upload"
                    @change="uploadUserCoverImage"
                    type="file"
                    accept="image/*"
                    class="hidden"
                  />
                </div>
              </div>
            </div>
            <vs-button 
             @click="saveCoverImage" 
             type="transparent"
              radius
              icon="icon-save"  
               icon-pack="feather"
              class="modify-edit-icon" 
              >Save</vs-button
            >
          </vs-popup>
          </div>-->
        <div class="profile-pic-section" v-if="!profileIsLoading">
          <div class="cust-mgleft pt-8">
            <UserAvatar
              :image="userDetails.usersDetails_UserImage"
              :text="userDetails.usersDetails_UserName"
              :size="'200px'"
              class="cust-profuserfont"
              @imageNotFound="userDetails.usersDetails_UserImage = null"
            />
            <!-- <vs-avatar
              v-if="userDetails.usersDetails_UserImage != null"
              class="profDPCover "
              :src="
                userDetails.usersDetails_UserImage.includes('uploads/userImage')
                  ? imageBaseUrl + userDetails.usersDetails_UserImage
                  : userDetails.usersDetails_UserImage
              "
            />

            <vs-avatar
              v-if="userDetails.usersDetails_UserImage == null"
              class="profDPCover "
              :src="imageBaseUrl + 'uploads/defaultImages/default_user.jpg'"
            /> -->

            <input
              id="prof-user-profile-Upload"
              @change="uploadUserImage"
              type="file"
              accept="image/*"
              class="hidden"
            />
            <div class="profile-backgd">
              <vs-button
                v-if="
                  profileUserId == token.userID ||
                  profileUserId == undefined ||
                  profileUserId == null
                "
                icon-pack="feather"
                class="editIcon"
                onclick="document.getElementById('prof-user-profile-Upload').click();"
                type="transparent"
                radius
                icon="icon-edit-2 text-primary"
              ></vs-button>
              <vs-popup
                classContent="popup-example"
                title="Change your profile picture"
                :active.sync="popupActive2"
              >
                <div class="vx-row">
                  <div class="vx-col w-full datasidebarprofile-padding_cropper">
                    <div class="flex items-start flex-col sm:flex-row w-full">
                      <div class="w-full datasidebarprofile-cropper-grp">
                        <Cropper
                          class="mr-8 rounded w-full img-uploadBox-cropper"
                          :src="newUserImage"
                          ref="cropper"
                          :stencilProps="stencilProps"
                        />

                        <!-- <img :src="newUserImage" class="mr-8 rounded h-24 w-24" /> -->
                      </div>
                      <div>
                        <input
                          id="user-icon-Upload"
                          @change="uploadUserImage"
                          type="file"
                          accept="image/*"
                          class="hidden"
                        />
                        <!--<vs-button
                      color="warning"
                      type="filled"
                      style="margin-left: 16px"
                      onclick="document.getElementById('user-icon-Upload').click();"
                          >Upload Profile Image</vs-button>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modify-grp-editbtn mt-4">
                  <vs-button
                    icon-pack="feather"
                    class="profile-savebtn bg-primary"
                    @click="saveProfileImage"
                    type="transparent"
                    radius
                    icon="icon-save"
                    >SAVE</vs-button
                  >
                </div>
              </vs-popup>
            </div>
            <!-- <vs-button
            icon-pack="feather"
            class="profile-savebtn bg-primary"
            @click="removeUserImage"
            type="transparent"
            radius
            icon="icon-save"
            >REMOVE</vs-button
          > -->
          </div>
          <div class="prof-lblparent">
            <div class="flex p-3">
              <div class="w-full">
                <span
                  class="h2-class-16 profilehead_slider_user_bold cust-mob-username mb-1"
                  >{{ userDetails.usersDetails_UserName }}</span
                >
                <UserType :item="userDetails" />
                <ProfileDetails :item="profileDetails" />
                <!-- <p
                class="h4-class profile_slider_user_bold profile_spacing_text "
              >{{ userDetails.usersDetails_TagLine }}</p> -->
                <div class="">
                  <!-- <span class="h2-class">About</span> -->
                  <div class="mt-2 mb-2 cust-aboutwidt">
                    <p>
                      {{ userDetails.usersDetails_TagLine }}
                    </p>
                  </div>
                  <div class="edit-icon flex mt-8">
                    <vs-button
                      v-if="
                        profileUserId == token.userID ||
                        profileUserId == undefined ||
                        profileUserId == null
                      "
                      class="mr-3 cust-fontsize-7 font-semibold"
                      type="border"
                      size="small"
                      @click="addNewData"
                      >Edit</vs-button
                    >
                    <vs-button
                      v-if="
                        profileUserId == token.userID ||
                        profileUserId == undefined ||
                        profileUserId == null
                      "
                      class="mr-3 cust-fontsize-7 font-semibold"
                      type="border"
                      size="small"
                      @click="removeProfileImage"
                      >Remove</vs-button
                    >
                    <vs-button
                      v-if="
                        profileUserId == token.userID ||
                        profileUserId == undefined ||
                        profileUserId == null
                      "
                      type="border"
                      size="small"
                      @click="logoutUser"
                      class="ml-1 cust-fontsize-7 font-semibold"
                      >Logout</vs-button
                    >
                  </div>
                </div>
              </div>

              <div class=""></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex items-center justify-end flex-wrap profile-header-nav p-6">
        <div class="block sm:hidden">
          <feather-icon
            @click="isNavOpen = !isNavOpen"
            icon="AlignJustifyIcon"
            v-show="!isNavOpen"
            class="vx-navbar-toggler cursor-pointer"
          />
          <feather-icon
            icon="XIcon"
            v-show="isNavOpen"
            @click="isNavOpen = !isNavOpen"
            class="vx-navbar-toggler cursor-pointer"
          />
        </div>
        <div
          :class="isNavOpen ? 'block': 'hidden'"
          class="w-full flex-grow sm:flex sm:items-center sm:w-auto"
        ></div>
      </div>-->
      <!-- <vx-navbar> -->
      <!-- </vx-navbar> -->
    </div>

    <!-- COL AREA -->
    <div class="mr-10-all mt-0">
      <div class="vx-row Profile_sep_prof cust-leftright-mg">
        <!-- COL 1 -->

        <div
          class="vx-col w-full lg:w-1/4 spacer lg:hidden sm:hidden profcard-width pd-0-all prof-height-fix"
        >
          <!-- ABOUT CARD -->

          <vx-card class="about_section pd-10 cust-bshadow-rem">
            <!-- <div class="p-2">
              <span class="h2-class">About</span>
              <div class="mt-2">
                <p>SBI Youth for India is an Indian rural fellowship programme initiated, funded and managed by the State Bank of India in partnership with reputed NGOs.</p>
                </div>
            </div> -->

            <!--         
<template>

  <vs-collapse>

    <vs-collapse-item>
      <div slot="header">
        Collapse item
      </div>
      Lorem....metus.
    </vs-collapse-item>
  </vs-collapse>
            </template>-->

            <div class="profile_main_page_mobileview_info">
              <!-- <profile-list :isSidebarActive="addGroupsSidebar" @closeSidebar="toggleViewGroupsDataSidebar" :data="viewSidebarData" /> -->
              <!-- <vs-button type="border" icon-pack="feather"  size="small" icon="icon-plus" @click="ViewGroupsData" class="ml-1">User List</vs-button> -->

              <div class="profile_lists mt-2">
                <div class="p-2">
                  <!-- <feather-icon icon="PlusIcon" class="ml-1  mr-2 Profile_page_icon" > </feather-icon> -->
                  <span
                    class="h3-class profile_topbar h2-class ml-1 mr-2 Profile_page_icon"
                  >
                    {{
                      profileUserId == token.userID ||
                      profileUserId == undefined ||
                      profileUserId == null
                        ? "My Channels"
                        : `${userDetails.usersDetails_UserName}'s Channels`
                    }}</span
                  >
                </div>
                <div class="Profile_group_section">
                  <div class="vx-row profile_card_scroll">
                    <div
                      class="vx-col w-full sm:w-1/2 lg:w-1/4 prof-grp-marg profile_page_card_image mr-4"
                      v-for="(group, index) of userGroups"
                      :key="'user-group-' + index"
                    >
                      <vx-card class="profile_card_square cust-bShadow">
                        <div class="profile_card_img_text">
                          <div slot="no-body">
                            <img
                              v-if="group.group_icon != null"
                              :src="imageBaseUrl + group.group_icon"
                              @error="
                                group.group_icon =
                                  'uploads/defaultImages/default_post.png'
                              "
                              alt="content-img"
                              class="card_img_size responsive card-img-top"
                            />
                            <img
                              v-if="group.group_icon == null"
                              :src="
                                imageBaseUrl +
                                'uploads/defaultImages/default_group.png'
                              "
                              alt="content-img"
                              class="card_img_size responsive card-img-top"
                            />
                          </div>
                          <div class="card_text">
                            <h5 class="mb-2 card_wrap_text">
                              {{ group.group_name }}
                            </h5>
                            <p class="text-grey">
                              {{ group.group_description }}
                            </p>
                          </div>
                        </div>
                      </vx-card>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <feather-icon  icon-pack="feather" icon="icon-plus"  @click="ViewGroupsData" class="ml-1 text-primary">Suggested Page</feather-icon> -->
            </div>

            <!-- OTEHR DATA -->
            <div
              v-for="(userProfileDetail, index) of userProfileDetails"
              :key="'profile-data-' + index"
            >
              <div
                class="mt-5 px-2"
                v-show="userProfileDetail.user_profile_details_value"
              >
                <h6>
                  {{ userProfileDetail.profile_detail_master_profile_name }}:
                </h6>
                <p>{{ userProfileDetail.user_profile_details_value }}</p>
              </div>
            </div>
            <!-- <div class="mt-5">
                        <h6>Joined:</h6>
                        <p>November 15, 2015</p>
                    </div>

                    <div class="mt-5">
                        <h6>Lives:</h6>
                        <p>New York, USA</p>
                    </div>

                    <div class="mt-5">
                        <h6>Email:</h6>
                        <p>bucketful@fiendhead.org</p>
                    </div>

                    <div class="mt-5">
                        <h6>Website:</h6>
                        <p>www.pixinvent.com</p>
                    </div>

                    <div class="social-links flex mt-4">
                        <feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon"></feather-icon>
                        <feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="TwitterIcon"></feather-icon>
                        <feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="InstagramIcon"></feather-icon>
            </div>-->
          </vx-card>

          <!-- PAGES SUGGESTION -->

          <!-- TWITER FEEDS -->
        </div>

        <!-- COL 2 -->
        <div
          class="vx-col w-full lg:w-1/4 profcard-width prof-height-fix a-box Profile_profile_about profile_section profile_group_mob"
        >
          <vx-card
            :title="
              profileUserId == token.userID ||
              profileUserId == undefined ||
              profileUserId == null
                ? 'My Channels'
                : ` ${userDetails.usersDetails_UserName}'s Channels`
            "
            class="mt-base profile_hd_pd web-scrlrt pb-2 cust-bshadow-rem"
          >
            <ul class="page-suggestions-list">
              <li
                class="page-suggestion flex items-center mb-4 pr-5 pl-5"
                v-for="(group, index) of userGroups"
                :key="'user-channel-' + index"
              >
                <div class="mr-3">
                  <vs-avatar
                    class="m-0"
                    v-if="group.group_icon != null"
                    @error="
                      group.group_icon =
                        'uploads/defaultImages/default_post.png'
                    "
                    :src="imageBaseUrl + group.group_icon"
                    size="35px"
                  />
                  <vs-avatar
                    class="m-0"
                    v-if="group.group_icon == null"
                    :src="
                      imageBaseUrl + 'uploads/defaultImages/default_group.png'
                    "
                    size="35px"
                  />
                </div>
                <div class="leading-tight">
                  <h5 class="h4-class">{{ group.group_name }}</h5>
                  <span class="profile_text-mute_grey h4-class text-xs">
                    {{ group.group_description }}
                  </span>
                </div>
                <div class="ml-auto"></div>
              </li>
            </ul>
          </vx-card>
        </div>
        <div
          id="posts"
          class="vx-col w-full lg:w-1/4 profil-midheight Profile_customWidthClass profile-md-secpd cust-bshadow-rem"
        >
          <vx-card
            class="mt-base smp_shadow_grp smp_card_card margin_top_groupspage cust-remboxsh"
            v-for="(groupPost, index) of groupPosts"
            :key="'profile-post-' + index"
          >
            <div class="post-header flex">
              <div
                class="flex items-center smp_text_pddd smp_top_10 w-full justify-between"
              >
                <div class="flex">
                  <div>
                    <UserAvatar
                      :image="groupPost.usersDetails_UserImage"
                      :text="groupPost.usersDetails_UserName"
                      :userID="groupPost.usersDetailsId"
                      :size="'45px'"
                      @imageNotFound="groupPost.usersDetails_UserImage = null"
                    />
                  </div>
                  <div class="ml-4 pt-2">
                    <h6
                      class="h-5-clsbold cursor-pointer fontWeight-600 h4-class"
                      @click="showProfile(groupPost.usersDetailsId)"
                    >
                      {{ groupPost.usersDetails_UserName }}
                    </h6>
                    <span class="textmute-usermes h4-class">{{
                      groupPost.post_group_mapping_created_datetimestamp
                    }}</span>
                  </div>
                  <div class="ml-4 pt-1">
                    <p class="h-5-clsbold h4-class cust-singleline-hid">
                      in {{ groupPost.groupNames }}
                    </p>
                  </div>
                </div>
                <div class="flex items-right float-r">
                  <!-- <vs-button
                    size="small"
                    class="mr-2"
                    @click="viewSinglePostPage(groupPost)"
                    >CLIENT'S VIEW</vs-button
                     v-if="$acl.check('admin')"
                    >-->
                  <!-- <vs-button
                    size="small"
                    class="mr-3"
                    @click="promotePost(groupPost.post_UniqueId)"
                    >PROMOTE</vs-button
                  > -->
                </div>
              </div>
            </div>
            <div class="smp_post_content">
              <PollView
                v-show="groupPost.PollQuestionId"
                :votedIds="groupPost.VotedIds"
                :PostId="groupPost.post_group_mapping_post_id"
                :PollId="groupPost.PollQuestionId"
                :Ids="groupPost.AnswersIds"
                :Question="groupPost.PollQuestion"
                :Answers="groupPost.PollAnswers"
                :AnswersVotes="groupPost.AnswersVotes"
              />
              <p
                v-show="groupPost.text != null"
                v-html="groupPost.text"
                class="h3-class"
              ></p>
              <p
                v-if="groupPost.imageText"
                v-html="groupPost.imageText"
                class="Grp-post-content"
              ></p>
              <p
                v-if="groupPost.videoText"
                v-html="groupPost.videoText"
                class="Grp-post-content"
              ></p>
              <p
                v-if="groupPost.audioText"
                v-html="groupPost.audioText"
                class="Grp-post-content"
              ></p>
              <p
                v-if="groupPost.blogText"
                v-html="groupPost.blogText"
                class="Grp-post-content"
              ></p>
            </div>
            <div class="post-media-container p-5">
              <!-- <video-player
                  ref="aplayer"
                  class
                  :options="playerOptionsYoutube(groupPost)"
                  v-if="
                    groupPost.url != null &&
                      (groupPost.url.includes('youtube') ||
                        groupPost.url.includes('youtu.be'))
                  "
                  v-once
                />-->
              <div id="app" v-if="groupPost.url != null">
                <link-prevue :url="groupPost.url" v-if="groupPost.url != null">
                  <template slot="loading">
                    <!-- set your custom loading -->
                  </template>
                  <template slot-scope="props">
                    <div class="card-block pd-10">
                      <h5 class="card-title">{{ props.title }}</h5>
                      <p class="card-text">{{ props.description }}</p>
                      <a
                        target="_blank"
                        v-bind:href="props.url"
                        class="btn btn-primary more-primarycolr"
                        >More</a
                      >
                    </div>
                    <img
                      class="mediaPost-Image mediaPost-mh"
                      :src="
                        props.img
                          ? props.img
                          : (props.img =
                              magittUrl +
                              'uploads/defaultImages/default_post.png')
                      "
                      :alt="props.title"
                      @click.stop="viewSinglePost(groupPost)"
                    />
                  </template>
                </link-prevue>
              </div>
              <img
                class="mediaPost-Image mediaPost-mh"
                :src="groupPost.image"
                @error="
                  groupPost.image =
                    magittUrl + 'uploads/defaultImages/default_post.png'
                "
                alt="user-upload"
                v-if="groupPost.image != null"
                @click="viewSinglePost(groupPost)"
              />
              <img
                class="mediaPost-Image mediaPost-mh"
                :src="groupPost.featured_image"
                @error="
                  groupPost.featured_image =
                    magittUrl + 'uploads/defaultImages/default_post.png'
                "
                alt="user-upload"
                v-if="groupPost.featured_image != null"
                @click.stop="viewSinglePost(groupPost)"
              />
              <video-player
                ref="player"
                class
                :options="playerOptions(groupPost)"
                v-else-if="groupPost.video_url != null"
                v-once
                @click.stop="viewSinglePost(groupPost)"
              />
              <audio controls v-else-if="groupPost.audio_url != null">
                <source :src="groupPost.audio_url" />
              </audio>
              <!-- <video-player
                  ref="aplayer"
                  class
                  :options="playerOptionsAudio(groupPost)"
                  v-else-if="groupPost.audio_url != null"
                  v-once
                  @click.stop="viewSinglePost(groupPost)"
                />-->
            </div>
            <!-- <div
              style="height: 100%;"
              class="pd-10"
              v-show="groupPost.body != null"
              v-html="groupPost.body"
              ></div>-->
            <div class="flex items-center mr-4 ml-4 grp-page-postbtn mt-3">
              <vs-button
                v-show="groupPost.body != null"
                size="small"
                @click="viewSinglePostPage(groupPost)"
                >Read More</vs-button
              >
            </div>
            <div class="cust-addborder flex justify-between mr-4 ml-4">
              <div class="post-likeheart">
                <!-- <main>
                      <input class="l" type="checkbox" />
                  </main>-->
                <feather-icon
                  class="mr-2 groups_like_icon"
                  icon="HeartIcon"
                  :svgClasses="{
                    'text-danger fill-current stroke-current':
                      groupPost.is_active,
                  }"
                  @click="
                    likePost(
                      groupPost.is_active,
                      groupPost.postId,
                      groupPost.likeId,
                      groupPost.post_user_id,
                      groupPost.post_UniqueId,
                      groupPost.usersDetails_UniqueId
                    )
                  "
                ></feather-icon>
                <span
                  class="likes-fonts"
                  v-if="groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0"
                ></span>
                <span
                  class="likes-fonts post-liketick"
                  v-else-if="groupPost.LIKECOUNT == 1"
                  >{{ groupPost.LIKECOUNT }} Like</span
                >
                <span class="likes-fonts" v-else
                  >{{ groupPost.LIKECOUNT }} Likes</span
                >
              </div>

              <div class="flex justify-left smp_mt_40">
                <div class="flex items-center likes-com-clss p-3">
                  <!-- <feather-icon
                    class="mr-2 primary-icon"
                    icon="MessageSquareIcon"
                    svgClasses="h-5 w-5"
                  ></feather-icon>-->
                  <span
                    v-if="
                      groupPost.comment_count == null ||
                      groupPost.comment_count == 0
                    "
                    >No Comments</span
                  >
                  <span v-else-if="groupPost.comment_count == 1"
                    >{{ groupPost.comment_count }} Comment</span
                  >
                  <span v-else>{{ groupPost.comment_count }} Comments</span>
                  <span></span>
                </div>
              </div>
            </div>
            <post-comment :post="groupPost" />
            <div class="">
              <div class="smp_post_comment smp_socl_mdl_cm groupspage_text">
                <div>
                  <UserAvatar
                    :image="$store.state.user.userImage"
                    :text="$store.state.user.username"
                    :userID="$store.state.user.userID"
                    :size="'35px'"
                    @imageNotFound="$store.dispatch('storeUserImage', null)"
                  />
                </div>
                <vs-input
                  class="smp_plce_comment smp_cmnt_border smp_social_page_mg_btt ml-2"
                  placeholder="Write a commments......"
                  vs-placeholder="Nombre"
                  v-model="groupPost.newComment"
                  @keydown.enter="
                    addComment(
                      groupPost.post_UniqueId,
                      groupPost.newComment,
                      groupPost.usersDetails_UniqueId
                    )
                  "
                />
                <div class="send_icon_setting">
                  <a v-if="groupPost.newComment != ''" class>
                    <i
                      class="feather icon-send feather-20 text-primary"
                      icon="icon-send"
                      @click="
                        addComment(
                          groupPost.post_UniqueId,
                          groupPost.newComment,
                          groupPost.usersDetails_UniqueId
                        )
                      "
                    ></i>
                  </a>
                </div>
              </div>
              <div class="smp_social_feed_float_btn" style="display: none">
                <vs-button
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  c
                  icon="icon-plus"
                  @click="addNewPostData()"
                ></vs-button>
              </div>
            </div>
          </vx-card>

          <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
            <div slot="no-more">
              <img
                class="w-100 img-responsive social_img Profile_page_social_imgs_endpost"
                :src="imageBaseUrl + 'uploads/defaultImages/no_posts.svg'"
              />
            </div>
            <div slot="no-results">
              <img
                class="w-100 img-responsive social_img Profile_page_social_imgs_endpost"
                :src="imageBaseUrl + 'uploads/defaultImages/no_posts.svg'"
              />
            </div>
          </infinite-loading>
        </div>

        <!-- <vs-sidebar
              position-right
              parent="body"
              default-index="1"
              color="primary"
              class="sidebarx post_sidebar"
              spacer
              v-model="active">
              <div class="Sidebar_header bg-primary">
                <div class="post_sildebar">
                  <feather-icon
                    icon="ChevronLeftIcon"
                    svgClasses="h-6 w-6"
                    class="text-white sidebar_btn_cross ChevronLeftIcon_icon"
                    @click.stop="active = !active"
                  />
                  <span class="h2-class profile_post_heading">POST</span>
                </div>
              </div>
           

              <div class="sidebar_img">
               
              </div>
              <div class="like_comment_section">
                <div class="comnt_post">
                  <img
                    src="@/Core/assets/images/portrait/f15.jpg"
                    class="user_comment_post"
                  />
                  <span class="user">Sumit</span>
                </div>
                <div class>
                  <span class="user_cmnt h3-class">Good initiative</span>
                </div>
                <div class="flex cmnt_rply">
                  <p class="user_cmtn_section user_rply">40min</p>
                  <p class>
                    <a href="#" class="user_rply rply_text_text">Reply</a>
                  </p>
                </div>
              </div>
              <div class="flex profile_post_img_like_sidebar">
                <div class="flex items-center likecmtn">
                 
                  <span
                    v-if="groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0"
                    >Likes</span
                  >
                  <span v-else-if="groupPost.LIKECOUNT == 1"
                    >{{ groupPost.LIKECOUNT }} Like</span
                  >
                  <span v-else>{{ groupPost.LIKECOUNT }} Likes</span>
                </div>
                <div class="flex items-center Profile_sidebar_comment">
                  <feather-icon
                    class="mr-1"
                    icon="MessageSquareIcon"
                    svgClasses="h-5 w-5"
                  ></feather-icon>
                  <span>Comments</span>
                </div>
              </div>
            </vs-sidebar> -->
        <!-- <vs-popup
              fullscreen
              title
              class="popuphide"
              :active.sync="popupActive">
              <div class="flex">
                <div class="post_img_class w-1/2">
                  <img
                    class="post_img_size"
                    :src="postPopupImage"
                    @error="
                      postPopupImage =
                        imageBaseUrl + 'uploads/defaultImages/default_post.png'
                    "
                    alt="user-upload"
                  />
                </div>
                <vx-card class="w-1/2 post_card">
                  <div class="flex profile_post_img_like">
                    <div class="capt_post">
                      <div class="comnt_post ml-4 mt-2">
                        <img
                          src="@/Core/assets/images/portrait/f15.jpg"
                          class="user_comment_post"
                        />
                        <h6 class="user user_text_dashboard">Sumit</h6>
                      </div>
                   
                      <div class="caption_post">
                        <span class>#Good initiative</span>
                      </div>

                      <div class="flex mt-6 ml-6">
                        <div class="flex items-center likecmtn w-3/4">
                         
                        </div>
                        <div class="flex items-center mr-3 w-1/2">
                          <feather-icon
                            class="mr-2"
                            icon="MessageSquareIcon"
                            svgClasses="h-5 w-5"
                          ></feather-icon>
                          <span></span>
                        </div>
                      </div>
                      <vs-divider />
                      <div class="user_like_comment">
                        <div class="flex">
                          <div class="user_img">
                            <img
                              src="@/Core/assets/images/portrait/f15.jpg"
                              class="user_comment_post"
                            />
                          </div>
                          <div class="users_comments">
                            <h6 class="user">Sumit</h6>
                            <h6 class="user_cmnt">Good initiative</h6>
                          </div>
                        </div>
                        <div class="flex user_comments_list">
                          <p class="user_cmtn_section user_rply">40min</p>
                          <p class>
                            <a href="#" class="user_rply">Reply</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </vx-card>
              </div>
            </vs-popup> -->
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
//import "video.js/dist/video-js.css";
import DataViewSidebar from "./DataViewSidebar.vue";
import ProfileList from "./ProfileList.vue";
import jwt from "jsonwebtoken";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const profileRepository = RepositoryFactory.get("profile");
import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const LDAPRepository = ClientRepositoryFactory.get("LDAP");
const postsRepository = RepositoryFactory.get("posts");
const dashboardRepository = RepositoryFactory.get("dashboard");
const notificationRepository = RepositoryFactory.get("notifications");
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { Cropper } from "vue-advanced-cropper";
import * as moment from "moment";
import "@/Core/Views/Commoncss/componentCss/Profile.css";
import LinkPrevue from "link-prevue";
require("videojs-youtube");
import PostComment from "../group/components/PostComment";
import PollView from "../group/components/PollView";
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
import UserType from "./UserType";
import ProfileDetails from "./ProfileDetails";
const defaultUserProfile = require("@/Core/assets/images/profile/user-uploads/user.png");
export default {
  data() {
    return {
      profileIsLoading: true,
      colorLoading: this.$vs.theme.primary,
      active: false,
      popupActive: false,
      postPopupImage: "",
      addNewDataSidebar: false,
      popupActive3: false,
      newUserImage: null,
      newUserCoverImage: null,
      popupActive2: false,
      userDetails: {},
      userProfileDetails: null,
      scrollY: null,
      addGroupsSidebar: false,
      sidebarData: {},
      viewSidebarData: {},
      isNavOpen: false,
      userPoll: "",
      groupPosts: [],
      postsPage: 1,
      postsLimit: 30,
      postsOffset: 0,
      textarea: null,
      stencilProps: {
        aspectRatio: 1 / 1,
      },
      stencilPropsCoverImage: {
        aspectRatio: 85 / 27,
      },
      isPublicCommunity: true,
      userGroups: [],
      profileUserId: null,
      user_info: {
        profile_img: require("@/Core/assets/images/profile/user-uploads/user-13.jpg"),
        cover_img: require("@/Core/assets/images/profile/user-uploads/cover.jpg"),
      },
      mediaExtensions: {
        img: ["jpg", "jpeg", "png", "bmp", "gif", "exif", "tiff"],
        video: ["avi", "flv", "wmv", "mov", "mp4", "3gp"],
      },
      suggestedFriends: [
        {
          name: "Carissa Dolle",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-5.png"),
          mutualFriends: 6,
        },
        {
          name: "Liliana Pecor",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-6.png"),
          mutualFriends: 3,
        },
        {
          name: "Isidra Strunk",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-7.png"),
          mutualFriends: 2,
        },
        {
          name: "Gerald Licea",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-8.png"),
          mutualFriends: 1,
        },
        {
          name: "Kelle Herrick",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-9.png"),
          mutualFriends: 1,
        },
        {
          name: "Cesar Lee",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-10.png"),
          mutualFriends: 1,
        },
        {
          name: "John Doe",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-11.png"),
          mutualFriends: 1,
        },
        {
          name: "Tonia Seabold",
          avatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
          mutualFriends: 1,
        },
      ],
      userLatestPhotos: [
        require("@/Core/assets/images/profile/user-uploads/user-01.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-02.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-04.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-03.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-05.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-06.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-07.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-08.jpg"),
        require("@/Core/assets/images/profile/user-uploads/user-09.jpg"),
      ],
      suggestedPages: [
        {
          img: require("@/Core/assets/images/profile/pages/page-09.jpg"),
          title: "Rockose",
          type: "Company",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-08.jpg"),
          title: "The Devil's",
          type: "Clothing Store",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-03.jpg"),
          title: "The Magician",
          type: "Public Figure",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-02.jpg"),
          title: "AC/DC",
          type: "Music",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-07.jpg"),
          title: "eat hard",
          type: "restaurant / bar",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-04.jpg"),
          title: "B4B",
          type: "Beauty Store",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-05.jpg"),
          title: "Kylie Jenner",
          type: "Public Figure",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-01.jpg"),
          title: "RDJ",
          type: "Actor",
        },
        {
          img: require("@/Core/assets/images/profile/pages/page-06.jpg"),
          title: "Taylor Swift",
          type: "Music",
        },
      ],
      polls: [
        {
          id: 59,
          title: "Who is the best actor in Marvel Cinematic Universe?",
          options: [
            {
              text: "RDJ",
              value: "rdj",
              voted: 58,
              usersVoted: [
                {
                  name: "Tonia Seabold",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
                },
                {
                  name: "Carissa Dolle",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-5.png"),
                },
                {
                  name: "Kelle Herrick",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-9.png"),
                },
                {
                  name: "Len Bregantini",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-10.png"),
                },
                {
                  name: "John Doe",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-11.png"),
                },
                {
                  name: "Tonia Seabold",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
                },
                {
                  name: "Dirk Fornili",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-2.png"),
                },
              ],
            },
            {
              text: "Chris Hemsworth",
              value: "chris hemsworth",
              voted: 16,
              usersVoted: [
                {
                  name: "Liliana Pecor",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-6.png"),
                },
                {
                  name: "Kasandra Nalevanko",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-1.png"),
                },
              ],
            },
            {
              text: "mark ruffalo",
              value: "mark ruffalo",
              voted: 8,
              usersVoted: [
                {
                  name: "Lorelei Lacsamana",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-4.png"),
                },
              ],
            },
            {
              text: "Chris Evans",
              value: "chris evans",
              voted: 16,
              usersVoted: [
                {
                  name: "Jeanie Bulgrin",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-8.png"),
                },
                {
                  name: "Graig Muckey",
                  avatar: require("@/Core/assets/images/portrait/small/avatar-s-3.png"),
                },
              ],
            },
          ],
        },
      ],
      twitterFeeds: [
        {
          authorAvatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
          authorUsername: "tiana59",
          authorDisplayName: "Tiana Vercetti",
          content:
            "I love cookie chupa chups sweet tart apple pie chocolate bar. Jelly-o oat cake chupa chups.",
          tags: ["js", "vuejs"],
          time: "Mon Dec 12 2018 07:46:05 GMT+0000 (GMT)",
        },
        {
          authorAvatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
          authorUsername: "tiana59",
          authorDisplayName: "Tiana Vercetti",
          content:
            "Carrot cake cake gummies I love I love tiramisu. Biscuit marzipan cookie lemon drops.",
          tags: ["python"],
          time: "Mon Dec 11 2018 01:05:05 GMT+0000 (GMT)",
        },
        {
          authorAvatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
          authorUsername: "tiana59",
          authorDisplayName: "Tiana Vercetti",
          content:
            "I love cookie chupa chups sweet tart apple pie chocolate bar. Jelly-o oat cake chupa chups .",
          tags: [],
          time: "Mon Dec 10 2018 03:33:05 GMT+0000 (GMT)",
        },
        {
          authorAvatar: require("@/Core/assets/images/portrait/small/avatar-s-12.png"),
          authorUsername: "tiana59",
          authorDisplayName: "Tiana Vercetti",
          content:
            "Muffin candy caramels. I love caramels tiramisu jelly. Pie I love wafer. Chocolate cake lollipop tootsie roll cake.",
          tags: ["django", "vuejs"],
          time: "Mon Dec 9 2018 08:47:05 GMT+0000 (GMT)",
        },
      ],
      wasSidebarOpen: null,
    };
  },
  computed: {
    mediaType() {
      return (media) => {
        if (media.img) {
          const ext = media.img.split(".").pop();
          if (this.mediaExtensions.img.includes(ext)) return "image";
        } else if (media.sources && media.poster) {
          // other validations
          return "video";
        }
      };
    },
    playerOptions() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.video_url }],
          poster: media.poster,
        };
      };
    },
    playerOptionsAudio() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.audio_url }],
          poster:
            this.imageBaseUrl +
            "uploads/defaultImages/default_audio_post_wide.jpg",
        };
      };
    },
    imageBaseUrl() {
      return this.baseUrlMagitt;
    },
    magittUrl() {
      return this.baseUrlMagitt;
    },
    playerOptionsYoutube() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          techOrder: ["youtube"],
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ type: "video/youtube", src: media.url }],
        };
      };
    },
    profileDetails() {
      return {
        mobile: this.userDetails.usersDetails_Mobile,
        email: this.userDetails.usersDetails_Email,
      };
    },
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      }, 3000);
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    openmycommunity() {
      this.$router.push("/pages/MyCommunities").catch(() => {});
    },
    OpenPostImg() {
      this.$router.push("/pages/Profilepost").catch(() => {});
    },
    async getGroupsForDashboard() {
      let obj = {};
      if (
        this.$store.state.memberId != this.token.userID &&
        this.$store.state.memberId != undefined &&
        this.$store.state.memberId != null
      ) {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            userId: this.$store.state.memberId,
          },
        };
      } else {
        obj = {
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          userData: {
            userId: this.token.userID,
          },
        };
      }

      const { data } = await dashboardRepository.getGroupsForDashboard(obj);

      this.userGroups = data;
    },
    uploadUserCoverImage(ev) {
      this.popupActive3 = true;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) =>
        this.$emit("load", (this.newUserCoverImage = e.target.result));
      reader.readAsDataURL(file);
    },
    async saveCoverImage() {
      this.cropCover();
      let payload = {
        userData: {
          id: this.token.userID,
          userCoverImage: this.newUserCoverImage,
          ownerMobile: this.token.userMobile,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await profileRepository.editUserProfileCoverPicture(
        payload
      );
      if (data.ResponseCode == 100) {
        this.userDetails.usersDetails_UserCoverImage = data.userCoverImage;
        this.popupActive3 = false;
      }
    },
    uploadUserImage(ev) {
      const supportedTypes = ["image/jpeg", "image/png"];
      const fileSize = 25 * 1024 * 1024;
      if (
        ev.target.files.length &&
        !supportedTypes.includes(ev.target.files[0].type)
      ) {
        this.unsupportedFormatAlert();
        return;
      } else if (ev.target.files.length && ev.target.files[0].size > fileSize) {
        this.largeFileSizeAlert();
        return;
      } else if (ev.target.files.length > 0) {
        this.popupActive2 = true;
        const file = ev.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) =>
          this.$emit("load", (this.newUserImage = e.target.result));
        reader.readAsDataURL(file);
      }
    },
    async saveProfileImage() {
      this.$vs.loading();
      this.crop();
      let payload = {
        userData: {
          id: this.token.userID,
          userImage: this.newUserImage,
          ownerMobile: this.token.userMobile,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await profileRepository.editUserProfilePicture(payload);

      if (data.ResponseCode == 100) {
        if (this.userDetails.usersDetails_UserImage == null) {
          this.userDetails.usersDetails_UserImage = data.userImage;
          this.$store.state.user.userImage = data.userImage;
        } else {
          this.userDetails.usersDetails_UserImage = `${
            data.userImage
          }?${new Date()}`;
          this.$store.state.user.userImage = `${data.userImage}?${new Date()}`;
        }
        this.$vs.loading.close();
        this.popupActive2 = false;
      }
    },
    async removeProfileImage() {
      this.$vs.loading();
      let payload = {
        userData: {
          id: this.token.userID,
          userImage: defaultUserProfile,
          ownerMobile: this.token.userMobile,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await profileRepository.editUserProfilePicture(payload);
      console.log("Userdata", data);
      if (data.ResponseCode == 100) {
        this.userDetails.usersDetails_UserImage = defaultUserProfile;
        //this.$store.state.user.userImage = defaultUserProfile;
        window.location.reload();
      }
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.newUserImage = canvas.toDataURL();
    },
    cropCover() {
      const { coordinates, canvas } = this.$refs.cropperCover.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.newUserCoverImage = canvas.toDataURL();
    },

    ViewGroupsData() {
      this.viewSidebarData = {};
      this.toggleViewGroupsDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    toggleViewGroupsDataSidebar(val = false) {
      this.addGroupsSidebar = val;
    },
    async logoutUser() {
      // localStorage.removeItem("token");
      // localStorage.removeItem("managertoken");
      localStorage.clear();
      this.$session.destroy();
      await LDAPRepository.logout({
        uuid: this.token.uuid,
      });
      this.$router.push("/pages/customadminlogin").catch(() => {});
    },
    async getUserDetails() {
      this.$vs.loading();
      this.profileIsLoading = true;
      ////debugger;
      let payload = {};
      if (
        this.$store.state.memberId != this.token.userID &&
        this.$store.state.memberId != undefined &&
        this.$store.state.memberId != null
      ) {
        payload = {
          userData: {
            id: this.$store.state.memberId,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
      } else {
        payload = {
          userData: {
            id: this.token.userID,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
      }
      const { data } = await profileRepository.getAllUserData(payload);

      if (data.length > 0) {
        this.$vs.loading.close();
        this.profileIsLoading = false;
        this.userProfileDetails = data;
        this.userDetails = data[0];
        this.userDetails.status = data[0].usersDetails_Status;
        this.userDetails.isManager =
          data[0].communityManager_UserId != null ? true : false;
        this.userDetails.isOwner =
          data[0].communityManager_Is_owner == 1 ? true : false;
        this.userDetails.isNormalUser =
          data[0].usersDetails_Password == null ? true : false;
        moment(data[0].usersDetails_DOB).format("DD-MM-YYYY");
      }
      // console.log(this.userDetails);
    },
    async getUserPosts() {
      ////debugger;
      let payload = {};
      if (
        this.$store.state.memberId != this.token.userID &&
        this.$store.state.memberId != undefined &&
        this.$store.state.memberId != null
      ) {
        payload = {
          userData: {
            id: this.$store.state.memberId,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          paging: {
            limit: this.postsLimit,
            offset: this.postsOffset,
          },
        };
      } else {
        payload = {
          userData: {
            id: this.token.userID,
            uuid: this.token.uuid,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
          paging: {
            limit: this.postsLimit,
            offset: this.postsOffset,
          },
        };
      }

      const { data } = await postsRepository.getPostsOfUser(payload);
      ////debugger;
      return new Promise((resolve) => {
        if (data) {
          if (data.length > 0) {
            data.forEach((element) => {
              // if (
              //   moment().diff(
              //     moment(element.post_group_mapping_created_datetimestamp),
              //     "hours"
              //   ) < 13
              // ) {
              element.post_group_mapping_created_datetimestamp = moment(
                element.post_group_mapping_created_datetimestamp
              ).fromNow();
              // } else {
              //   element.post_group_mapping_created_datetimestamp = moment(
              //     element.post_group_mapping_created_datetimestamp
              //   ).format("DD-MM-YYYY HH:mm");
              // }
              if (element.image != null) {
                if (element.image.includes("uploads/postImages")) {
                  element.image = this.imageBaseUrl + element.image;
                }
              }
              if (element.video_url != null) {
                if (element.video_url.includes("uploads/postVideos")) {
                  element.video_url = this.imageBaseUrl + element.video_url;
                }
              }
              if (element.audio_url != null) {
                if (element.audio_url.includes("uploads/postAudios")) {
                  element.audio_url = this.imageBaseUrl + element.audio_url;
                }
              }
              if (element.featured_image != null) {
                if (element.featured_image.includes("uploads/postImages")) {
                  element.featured_image =
                    this.imageBaseUrl + element.featured_image;
                }
              }
              if (element.PollQuestion !== null) {
                element.VotedIds =
                  element.VotedIds !== null ? element.VotedIds : "";
                element.AnswersIds =
                  element.AnswersIds !== null
                    ? element.AnswersIds.split(",")
                    : [];
                element.PollAnswers =
                  element.PollAnswers !== null
                    ? element.PollAnswers.split(",")
                    : [];
                element.AnswersVotes =
                  element.AnswersVotes !== null
                    ? element.AnswersVotes.split(",")
                    : [];
              }
              element.comments = [];
              element.newComment = "";
              this.groupPosts.push(element);
            });
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    },
    infiniteHandler($state) {
      this.getUserPosts().then((data) => {
        if (data) {
          this.postsPage += 1;
          this.postsOffset += this.postsLimit;
          $state.loaded();
        } else {
          $state.complete();
        }
      });
      // axios.get(api, {
      //   params: {
      //     page: this.page,
      //   },
      // }).then(({ data }) => {
      //   if (data.hits.length) {
      //     this.page += 1;
      //     this.list.push(...data.hits);
      //     $state.loaded();
      //   } else {
      //     $state.complete();
      //   }
      // });
    },
    async addComment(postId, comment, uuid) {
      //insertComment
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        userId: this.token.uuid,
        comment: comment,
        commenterName: this.token.username,
      };

      const { data } = await postsRepository.insertComment(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        let post =
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postId)
          ];
        // if (
        //   !this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment
        // ) {
        //   this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment = comment;
        //   this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment_user_name = this.token.username;
        //   this.groupPosts[
        //     this.groupPosts.findIndex(x => x.post_UniqueId === postId)
        //   ].first_comment_user_image = this.token.userImage;
        // } else {
        post.comments.push({
          usersDetails_UserImage: this.token.userImage,
          usersDetails_UserName: this.token.username,
          comments_comment: comment,
          replyShow: false,
          replies: [],
          showReplies: false,
        });
        // }
        this.insertCommentNotification(postId, uuid);
        post.newComment = "";

        post.comment_count =
          post.comment_count == null ? 1 : parseInt(post.comment_count) + 1;
      }
    },
    async likePost(status, postId, likeId, userId, postuid, uuid) {
      if (likeId) {
        let self = this;
        let payload = {
          likeData: {
            id: likeId,
            isActive: !status,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.editLikedPost(payload);

        if (data.ResponseCode == 100) {
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.likeId === likeId)
          ].is_active = !status;
          if (!status) {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.likeId === likeId)
            ].LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.likeId === likeId)
            ].LIKECOUNT -= 1;
          }
        } else {
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } else {
        let self = this;
        let payload = {
          likeData: {
            postId: postuid,
            groupId: this.$store.state.groupId,
            userId: self.token.uuid,
            isActive: 1,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.likePost(payload);

        if (data.ResponseCode == 100) {
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
          ].is_active = 1;
          this.groupPosts[
            this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
          ].likeId = data.insertId;
          if (!status) {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
            ].LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPosts[
              this.groupPosts.findIndex((x) => x.post_UniqueId === postuid)
            ].LIKECOUNT -= 1;
          }
        } else {
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    async insertLikeNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "liked your post",
            notificationTitle: "Likes",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async insertCommentNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "commented on your post",
            notificationTitle: "Comments",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    viewSinglePostPage(postData) {
      // this.$router
      //   .push({
      //     name: "page-IndividualPost",
      //     params: { groupPost: postData }
      //   })
      //   .catch(() => {});
      // let rawString = {
      //   postId: postData.post_UniqueId,
      //   community: currentCommunityName.communityName,
      //   communityColor: localStorage.getItem("communityColor"),
      //   communityId: localStorage.getItem("communityID")
      // };
      // rawString = JSON.stringify(rawString);
      // let postLink =
      //   window.document.location.origin + "/posts?pid=" + btoa(rawString);
      // let postLink = `${window.document.location.origin}/posts?pid=${postData.post_UniqueId}`;
      let routeData = this.$router.resolve({
        path: `/posts?pid=${postData.post_UniqueId}`,
      });
      window.open(routeData.href, "_blank");
    },
    showProfile(userid) {
      this.$store.state.memberId = userid;
      // this.$router.replace("/community-user-profile").catch(() => {});
      this.reloadProfile();
    },
    reloadProfile() {
      if (this.$store.state.memberId != undefined) {
        this.profileUserId = this.$store.state.memberId;
      }
      this.getUserDetails();
      this.getGroupsForDashboard();
    },
    unsupportedFormatAlert() {
      this.$swal({
        icon: "error",
        title: "Unsupported File Format",
      });
    },
    largeFileSizeAlert() {
      this.$swal({
        icon: "error",
        title: "File Size too large",
      });
    },
  },

  components: {
    videoPlayer,
    DataViewSidebar,
    ProfileList,
    Cropper,
    LinkPrevue,
    PostComment,
    PollView,
    UserAvatar,
    UserType,
    ProfileDetails,
  },
  mounted() {
    this.getUserDetails();
    // this.getUserPosts();
    this.getGroupsForDashboard();
    //this.wasSidebarOpen = this.$store.state.reduceButton;
    //this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
  created() {
    if (localStorage.getItem("private")) {
      this.isPublicCommunity = false;
    }
    if (this.$store.state.memberId != undefined) {
      this.profileUserId = this.$store.state.memberId;
    }
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit("TOGGLE_REDUCE_BUTTON", false);
  },
};
</script>
