<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="modify-grpparent-div">
      <div class="flex px-4 sidebar-topfixpart bg-primary">
        <feather-icon
          icon="ChevronLeftIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer"
        ></feather-icon>
        <vs-button class="h2-class pd-10 cust-remove-boxsh"
          >EDIT PROFILE</vs-button
        >
      </div>
      <div id="demo-basic-card">
        <div class="vx-row">
          <!--<div class="vx-col w-full m-2">-->
          <div class="vx-col w-full">
            <vs-tabs class="mt-16 mb">
              <vs-tab
                class="mb-0 mt-0"
                label="Basic Details"
                icon-pack="feather"
                icon="icon-users"
              >
                <!--<div class="vx-row mb-6">
                 
                  </div>
                </div>-->
                <div class="vx-row prof-input-md inbox_field">
                  <div class="vx-col w-full">
                    <!-- <vs-input class="w-full" label="Name" v-model="name" />-->
                    <vs-input
                      class="w-full"
                      placeholder="Name"
                      v-model="name"
                    />
                  </div>
                </div>
                <div class="vx-row prof-input-md inbox_field">
                  <div class="vx-col w-full">
                    <!--<vs-input class="w-full" label="Phone" v-model="phone" />-->
                    <vs-input
                      class="w-full"
                      placeholder="Phone"
                      :disabled="true"
                      v-model="phone"
                    />
                  </div>
                </div>
                <!-- <div class="vx-row prof-input-md inbox_field">
                  <div class="vx-col w-full prof-sidbr-gender">
                    <span>Gender</span>
                    <ul class="centerx pd-10">
                      <li class="li_1">
                        <vs-radio v-model="gender" vs-value="1">
                          <span class="pd-10">Male</span>
                        </vs-radio>
                      </li>
                      <li class="li_1">
                        <vs-radio v-model="gender" vs-value="2">
                          <span class="pd-10">Female</span>
                        </vs-radio>
                      </li>
                      <li class="li_1">
                        <vs-radio v-model="gender" vs-value="3">
                          <span class="pd-10">Others</span>
                        </vs-radio>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="vx-row">
                  <datepicker
                    placeholder="Select Date"
                    v-model="dob"
                    class="w-full ml-8 mr-8 datpick-cal-prof"
                  ></datepicker>
                </div> -->
                <div class="vx-row prof-input-md inbox_field">
                  <div class="vx-col w-full">
                    <!--<vs-textarea class="w-full" v-model="tagline" />-->
                    <vs-input
                      class="w-full"
                      placeholder="Tagline"
                      v-model="tagline"
                    />
                  </div>
                </div>
              </vs-tab>

              <!-- <vs-tab
                v-if="formFields.length > 0"
                label="Additional Details"
                icon-pack="feather"
                icon="icon-info"
                class="DataViewSidebar_margin_profile"
              >
                <div
                  class="vx-row mb-0 inbox_field"
                  v-for="formField of formFields"
                  :key="formField.id"
                >
                  <div class="vx-col w-full mt-6 DataViewSidebar_margin">
                    <vs-input
                      class="w-full"
                      label-placeholder="Label-placeholder"
                      v-model="formField.value"
                    />
                  </div>
                </div>
              </vs-tab> -->
            </vs-tabs>
          </div>
        </div>
        <div class="bottom-fix-btn">
          <vs-button
            class="bg-primary w-full border-rem h2-class"
            color
            type="filled"
            @click="saveUserProfileSettings"
            >SAVE</vs-button
          >
        </div>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
import { videoPlayer } from "vue-video-player";
import VxTimeline from "@/Core/components/timeline/VxTimeline.vue";
import "video.js/dist/video-js.css";
//import axios from "axios";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const profileRepository = RepositoryFactory.get("profile");
import { Cropper } from "vue-advanced-cropper";
import "@/Core/Views/Commoncss/componentCss/allSidebar.css";
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import validationMixin from "@/Core/mixins/validationMixin";

export default {
  mixins: [validationMixin],
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      value1: "",
      value2: "",
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      else {
        let token = localStorage.getItem("token");
        this.token = jwt.decode(token);
        var data = this.getFieldData();
        if (data) {
          this.getUserProfileData();
        }
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
    name(val) {
      this.scriptValidation(val, "name");
    },
    tagline(val) {
      this.scriptValidation(val, "tagline");
    },
  },
  data() {
    return {
      date: null,
      dataId: null,
      dataName: "",
      dataCategory: null,
      dataImg: null,
      dataOrder_status: "pending",
      dataPrice: 0,
      card_1: {},
      newUserImage: null,
      newUserCoverImage: null,
      name: null,
      phone: null,
      gender: null,
      dob: null,
      tagline: null,
      additionalDetail1: null,
      formFields: [],
      stencilProps: {
        aspectRatio: 1 / 1,
      },
      stencilPropsCoverImage: {
        aspectRatio: 85 / 27,
      },
      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" },
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    },
  },
  methods: {
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = "";
      this.dataCategory = null;
      this.dataOrder_status = "pending";
      this.dataPrice = 0;
      this.dataImg = null;
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    uploadUserImage(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) =>
        this.$emit("load", (this.newUserImage = e.target.result));
      reader.readAsDataURL(file);
    },
    uploadUserCoverImage(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) =>
        this.$emit("load", (this.newUserCoverImage = e.target.result));
      reader.readAsDataURL(file);
    },
    async saveUserProfileSettings() {
      if (this.name == "" || this.tagline == "") {
        return this.$swal("", "field cannot be empty", "error");
      }
      const regex = /^[a-zA-Z ]*$/;
      const result = this.name.match(regex);
      const tagname = this.tagline.match(regex);
      if (tagname == null) {
        return this.$swal("", "Please Enter Valid tagline", "error");
      }
      if (result == null) {
        return this.$swal("", "Please Enter Valid Name", "error");
      }
      // if (this.newUserImage) {
      //   this.crop();
      // }
      // if (this.newUserCoverImage) {
      //   this.cropCover();
      // }
      var self = this;
      var obj = {
        userData: {
          ownerName: self.name,
          ownerMobile: self.phone,
          dob: self.dob,
          gender: self.gender,
          source: "website",
          ownerEmail: null,
          block_status: 0,
          userImage:
            self.newUserImage != null
              ? self.newUserImage.replace(self.baseUrlMagitt, "")
              : null,
          userCoverImage:
            self.newUserCoverImage != null
              ? self.newUserCoverImage.replace(self.baseUrlMagitt, "")
              : null,
          tagline: self.tagline,
          id: this.token.userID,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
        profileData: self.formFields,
      };
      const { data } = await profileRepository.saveUserProfileSettings(obj);

      if (data.success) {
        self.$vs.notify({
          title: "Updated",
          text: "Profile Updated",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });
        this.$parent.userDetails.usersDetails_UserName = self.name;
        this.isSidebarActiveLocal = false;
      }
    },
    async getFieldData() {
      var self = this;
      var obj = {
        communityName: currentCommunityName.communityName,
        communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
        communitiesType: 1,
        communitiesSubType: 0,
      };
      const { data } = await profileRepository.getFieldData(obj);

      data.forEach((element) => {
        self.formFields.push({
          id: null,
          name: element.profile_detail_master_profile_name,
          profile_detail_master_id: element.profile_detail_masterId,
          value: "",
        });
      });
      return true;
    },
    async getUserProfileData() {
      var obj = {
        userData: {
          id: this.token.userID,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: "b2146af0-15c3-11ea-b55d-bdde97cb4377",
          communitiesType: 1,
          communitiesSubType: 0,
        },
      };
      const { data } = await profileRepository.getUserProfileData(obj);

      this.setUserProfileData(data[0]);
      data.forEach((element) => {
        let index = this.formFields.findIndex(
          (x) =>
            x.profile_detail_master_id ==
            element.user_profile_details_profile_detail_master_id
        );
        if (index > -1) {
          this.formFields[index].id = element.user_profile_detailsId;
          this.formFields[index].value = element.user_profile_details_value;
        }
      });
    },
    setUserProfileData(userData) {
      this.name = userData.usersDetails_UserName;
      this.phone = userData.usersDetails_Mobile;
      this.dob = userData.usersDetails_DOB;
      this.gender = userData.usersDetails_Gender;
      if (userData.usersDetails_UserImage != null) {
        this.newUserImage =
          this.baseUrlMagitt + userData.usersDetails_UserImage;
      } else {
        this.newUserImage = userData.usersDetails_UserImage;
      }
      if (userData.usersDetails_UserCoverImage != null) {
        this.newUserCoverImage =
          this.baseUrlMagitt + userData.usersDetails_UserCoverImage;
      } else {
        this.newUserCoverImage = userData.usersDetails_UserCoverImage;
      }
      this.tagline = userData.usersDetails_TagLine;
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.newUserImage = canvas.toDataURL();
    },
    cropCover() {
      const { coordinates, canvas } = this.$refs.cropperCover.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.newUserCoverImage = canvas.toDataURL();
    },
  },
  mounted() {},
  components: {
    VuePerfectScrollbar,
    Datepicker,

    videoPlayer,
    VxTimeline,
    Cropper,
  },
};
</script>
